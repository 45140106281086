"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OtherPage = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = __importStar(require("react"));
const jotai_1 = require("jotai");
const atoms_1 = require("../../../../atoms");
const api_util_1 = require("../../../../utils/api.util");
require("./other.css");
const OtherPage = () => {
    const [user, setUser] = (0, jotai_1.useAtom)(atoms_1.authUserAtom);
    const [url, setUrl] = (0, react_1.useState)("");
    const [isIsraeliService, setIsIsraeliService] = (0, react_1.useState)(null);
    /**Test ISRAELI sites here:
     * https://www.wix.com/blog/wix-websites-examples#viewer-el5vc
     */
    const checkSite = async () => {
        try {
            const result = await (0, api_util_1.getData)(user.refreshToken, `/site/search?url=${encodeURIComponent(url)}`, null, null, setUser);
            setIsIsraeliService(result.data.isIsraeliService);
        }
        catch (error) {
            console.error(error);
        }
    };
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            checkSite();
        }
    };
    return ((0, jsx_runtime_1.jsx)("div", { className: "page", children: (0, jsx_runtime_1.jsxs)("div", { className: "custom-table-container", children: [(0, jsx_runtime_1.jsx)("h2", { children: "Check Website Origin" }), (0, jsx_runtime_1.jsx)("h4", { children: "Enter a website URL to check if it is Israeli-based or uses Israeli services like Wix." }), (0, jsx_runtime_1.jsx)("span", { children: " Note: This search focuses primarily on wix. Please do extra research" }), (0, jsx_runtime_1.jsxs)("div", { className: "input-group", children: [(0, jsx_runtime_1.jsx)("input", { type: "text", value: url, onChange: (e) => setUrl(e.target.value), placeholder: "Enter website URL", className: "url-input", onKeyPress: handleKeyPress }), (0, jsx_runtime_1.jsx)("button", { onClick: checkSite, className: "check-button", children: "Check Site" })] }), isIsraeliService !== null && ((0, jsx_runtime_1.jsx)("div", { className: `result ${isIsraeliService ? 'israeli' : 'not-israeli'}`, children: isIsraeliService ? "This site is Israeli-based or uses Israeli services." : "This site is not Israeli-based or does not use Israeli services." }))] }) }));
};
exports.OtherPage = OtherPage;
